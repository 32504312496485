// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import it from './locales/it.json';

const resources = {
  en: {
    translation: en
  },
  it: {
    translation: it
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    keySeparator: false, // We do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
