import React, { useState } from 'react';
import PropTypes from 'prop-types';

const StoryButton = ({ imageUrl, onClick, clicked, desc }) => {
  const [isTouch, setIsTouch] = useState(false);

  const handleTouchStart = (event) => {
    setIsTouch(true); // Indicate a touch event has started
  };

  const handleClick = (event) => {
    if (isTouch) {
      setIsTouch(false); // Reset flag if touch is detected
      return; // Do nothing if a touch event is in progress
    }
    onClick(); // Call the click handler
  };

  const handleTouchEnd = (event) => {
    if (isTouch) {
      setIsTouch(false); // Reset flag after touch ends
      onClick(); // Call the click handler
    }
  };

  return (
    <div
      style={styles.container}
      onClick={handleClick}  // Mouse click event
      onTouchStart={handleTouchStart}  // Touch start event
      onTouchEnd={handleTouchEnd}  // Touch end event
    >
      <div
        style={{
          ...styles.outerCircle,
          opacity: clicked ? 0.5 : 1,
          WebkitOpacity: clicked ? 0.5 : 1,
        }}
      >
        <div style={styles.innerCircle}>
          <img src={imageUrl} alt="button" style={styles.buttonImage} />
        </div>
      </div>
      <p style={styles.text}>{desc}</p>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '10px',
  },
  outerCircle: {
    width: '65px',
    height: '65px',
    borderRadius: '50%',
    border: '3px solid #144552',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease', // Smooth transition for opacity
    WebkitTransition: 'opacity 0.3s ease', // WebKit prefix
  },
  innerCircle: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    border: '3px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonImage: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  text: {
    marginTop: '5px',
    textAlign: 'center',
    fontSize: '14px', // Ensure legibility on smaller screens
    lineHeight: '1.2',
    WebkitFontSmoothing: 'antialiased', // Improve text rendering on iPhones
  },
};

StoryButton.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  clicked: PropTypes.bool,
};

export default StoryButton;
