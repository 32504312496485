import './App.css';
import React, { useState } from 'react';
import CountdownTimer from './CountdownTimer';
import FAQ from './FAQ';
import { useTranslation } from 'react-i18next';
import FullScreenOverlay from './FullScreenOverlay';
import StoryButton from './StoryButton';

function App() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [showOverlay, setShowOverlay] = useState([false, false, false]);
  const [clickedIndices, setClickedIndices] = useState([]);
  const images_prep = [

    '/prep1.jpeg',
    '/prep5.jpeg',
    '/prep3.jpeg',
    '/prep2.jpeg',
    '/prep7.jpeg',
    '/prep4.jpeg',


  ];

  const images_prop = [
    '/prop3.jpeg',
    '/prop2.jpeg',
    '/prop4.jpeg',
  ];

  const images_civil = [
    '/civil_5.jpeg',
    '/civil_6.png',
    '/civil_2.png',
    '/civil_7.jpeg',

  ];
  const wdate = new Date('2024-10-05T15:30:00');

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };


  const toggleOverlay = (index) => {
    setShowOverlay(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index]; // Toggle the overlay visibility
      return newState;
    });

    // Only update clicked indices if the overlay is being opened
    setClickedIndices(prevIndices => {
      if (!prevIndices.includes(index)) {
        return [...prevIndices, index];
      }
      return prevIndices;
    });
  };

  return (
    <div className="main">

      <div className='tac nobm'>
        <span className={`lang-selection ${selectedLanguage === 'en' ? 'selected' : ''}`} onClick={() => changeLanguage('en')}>
          English
        </span>
        <b>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</b>
        <span className={`lang-selection ${selectedLanguage === 'it' ? 'selected' : ''}`} onClick={() => changeLanguage('it')}>
          Italiano
        </span>

        <p className='title'>Luigi & Chiara</p>
        <div style={styles.appContainer}>



         <StoryButton
            imageUrl={'/porposal.gif'}
            onClick={() => toggleOverlay(0)}  // Use toggleOverlay to open or close
            clicked={clickedIndices.includes(0)}
            desc={t('Proposal')}
          />
          {showOverlay[0] && (
            <FullScreenOverlay key={`overlay-0-${Date.now()}`} images={images_prop} onClose={() => toggleOverlay(0)} />
          )}

          <StoryButton
            imageUrl={'/wedding_prep.gif'}
            onClick={() => toggleOverlay(1)}  // Use toggleOverlay to open or close
            clicked={clickedIndices.includes(1)}
            desc={t('Wedding Prep')}
          />
          {showOverlay[1] && (
            <FullScreenOverlay key={`overlay-1-${Date.now()}`} images={images_prep} onClose={() => toggleOverlay(1)} />
          )}

          <StoryButton
            imageUrl={'/wedding.gif'}
            onClick={() => toggleOverlay(2)}  // Use toggleOverlay to open or close
            clicked={clickedIndices.includes(2)}
            desc={t('Civil Wedding')}
          />
          {showOverlay[2] && (
            <FullScreenOverlay key={`overlay-2-${Date.now()}`} images={images_civil} onClose={() => toggleOverlay(2)} />
          )}

        </div>
      </div>
      <div className='tac nobm'>
        <p className='btitle notm'>{t("5th October 2024")}</p>


      </div>
      <div>
        <div className='cards'>
          <div className='card'>
            <div className="card-image" style={{ backgroundImage: "url('/castle.jpeg')" }}></div>
            <div className="card-content">
              <h3>{t('Our wedding')}</h3>
              <ul>
                <li><b>{t('Cerimony')}</b>: Chiesa della Trasfigurazione (Taurisano)</li>
                <li><b>{t('Reception')}</b>: Castello Monaci (Salice Salentino)</li>
              </ul>
              <a className='more-link' href='#schedule'>{t('More')}</a>
            </div>
          </div>

          <div className="card">
            <div className="card-image" style={{ backgroundImage: "url('/kermit.gif')" }}></div>
            <div className="card-content">
              <h3>{t('Directions')}</h3>
              <p>
                {t('Do not get lost.')}
              </p>
              <a className='more-link' href='#directions'>{t('More')}</a>
            </div>
          </div>

          <div className='card'>
            <div className="card-image" style={{ backgroundImage: "url('/toddler.gif')" }}></div>
            <div className="card-content">
              <h3>{t('Kiddos')}</h3>
              <p>{t('Your little ones are welcomed, too!')}</p>
              <a className='more-link' href='#baby'>{t('More')}</a>
            </div>
          </div>

          <div className='card'>
            <div className="card-image" style={{ backgroundImage: "url('/allergies-will.gif')" }}></div>
            <div className="card-content">
              <h3>{t('Allergies')}</h3>
              <p>{t("We care about everyone's well-being: our menu is designed to accommodate all allergies, so you can enjoy the celebration worry-free!")}</p>
              <a className='more-link' href='#allergy'>{t('More')}</a>
            </div>
          </div>
        </div>
        {/* <img className='cover' src='.\castle.jpeg'></img> */}
        <div className='details'>
          <div id='rsvp' className='section'>
            <h2>RSVP</h2>
            <p className='desc'>
              {t("Ciao belli! We'd love to see your shining faces at our upcoming celebration, but to ensure we have enough cake for everyone, we kindly ask you to RSVP before 05.09.2024.")}
            </p>
          </div>
          <div id='schedule' className='section'>
            <h2>{t('Schedule & transportation')}</h2>
            <p className='desc'>
              {t("The wedding cerimony will take place in the town we first met, Taurisano. After the ceremony, we invite you to join us for a dinner and party in the beautiful Castello Monaci.")}
            </p>
            <p className='desc'>
              {t("To make the transition easy and comfortable, we have arranged a shuttle service (2 buses) that will transport you from Taurisano to Castello Monaci. If you prefer to drive, car directions will be provided below. Parking is available both near the church and at the reception. If you  wish to use the shuttle service please, let us know in advance, preferably by 05.09.2024. After the dinner, you can show us your best dance moves at the party.")}
            </p>
            <ul>
              <li className='desc'>{t("Cerimony starts at 15:30")}</li>
              <li className='desc'>{t("Shuttle service leaves Taurisano from the meeting point at ca. 17:30")}</li>
              <li className='desc'>{t("Arrival at Castello Monaci at 19:00")}</li>
              <li className='desc'>{t("First shuttle service trip back to Taurisano ca. 01:00")}</li>
              <li className='desc'>{t("Second shuttle service trip back to Taurisano after the party ca. 03:00")}</li>
            </ul>
          </div>

          <div id='directions' className='section'>
            <h2>{t('Points of interest')}</h2>

            <ul>
              <li className='desc'>{t("Nearest airport: Brindisi Airport/Aeroporto del Salento (BDS)")}<br></br><a className='map-link' href='https://maps.app.goo.gl/B77e1Y51kdbBk6Ka7'>show in maps</a></li>
              <li className='desc'>{t('Church: Chiesa della Trasfigurazione')}<br></br><a className='map-link' href='https://maps.app.goo.gl/XPNau6NtZiurmXVn8'>show in maps</a></li>
              <li className='desc'>{t("Bus meeting point: Piazza unità d'Italia, Taurisano")}<br></br><a className='map-link' href='https://maps.app.goo.gl/V9sMap6bhvszHc7w7'>show in maps</a></li>
              <li className='desc'>{t('Wedding reception: Castello Monaci, Salice Salentino')}<br></br><a className='map-link' href='https://maps.app.goo.gl/nRk3pGd3j2j9Wfgj8'>show in maps</a></li>
            </ul>
          </div>

          <div id='baby' className='section'>
            <h2>Baby sitting</h2>
            <p className='desc'>
              {t("For our adorable little guests aged between 4 and 12 years old, there will be a special table just for them at the wedding reception. With dedicated babysitters to entertain and delight, we'll make sure it's a magical and memorable day for them, too.")}


            </p>
          </div>
          <div id='dresscode' className='section'>
            <h2>Dress code</h2>
            <p className='desc'>
              {t('We kindly request our guests to refrain from wearing green and its shades, as this colour has been assigned to a group of people with a dedicated role. We appreciate your understanding and cooperation.')}</p>
          </div>
          <div id='allergy' className='section'>
            <h2>{t('Allergies')}</h2>
            <p className='desc'>
              {t("Our reception venue has multiple bathrooms but we don't want it to be the highlight of your day. Therefore, we warmly invite you to inform us of any allergies or intolerances.")}

            </p>
          </div>

          <div id='wheretogo' className='section'>
            <h2>{t('What to see in Salento')}</h2>
            <p className='desc'>
              {t("For everyone enjoying a few days in our beloved Salento, we've created a map highlighting must-see spots, including charming restaurants and wonderful towns and locations to visit. Happy exploring!")}
            </p>
            <iframe src="https://www.google.com/maps/d/embed?mid=1WdQfQlLQNhODY5_00feG9bfIebyGRSM&ehbc=2E312F&noprof=1" width="100%" height="400"></iframe>
          </div>

          <FAQ />
          <div className='counter'>
            <br></br><br></br>
            <h1>{t("We're grateful to have you as an important part of our special day!")}</h1>
            <CountdownTimer targetDate={wdate}></CountdownTimer>
          </div>


        </div>

        <footer className='foot'>made with &#10084;  &#10084; &#10084; &#10084; &#10084; by Luigi & Chiara</footer>

      </div></div>
  );
}

const styles = {
  appContainer: {
    display: 'flex',
    marginTop: '2em',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default App;
