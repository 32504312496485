import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const FullScreenOverlay = ({ images, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartX = useRef(null);
    const debounceRef = useRef(null);
    const isTouchEvent = useRef(false);
    const isInitialRender = useRef(true);
    const intervalRef = useRef(null);
    const ignoreNextClick = useRef(false); // Flag to ignore the initial click based on device type

    // Function to determine if the device is mobile
    const isMobile = () => window.innerWidth < 768; // Define your mobile breakpoint here

    const nextImage = useCallback(() => {
        if (debounceRef.current) return;
        // console.log('nextImage called');
        setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        debounceRef.current = setTimeout(() => {
            debounceRef.current = null;
        }, 300);
    }, [images.length]);

    const prevImage = useCallback(() => {
        if (debounceRef.current) return;
        // console.log('prevImage called');
        setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
        debounceRef.current = setTimeout(() => {
            debounceRef.current = null;
        }, 300);
    }, [images.length]);

    useEffect(() => {
        if (isInitialRender.current) {
            // console.log('Initial render: setting index to 0');
            setCurrentIndex(0);
            isInitialRender.current = false;
            // Set ignoreNextClick based on device type
            ignoreNextClick.current = isMobile();
        }
    }, [images]);

    useEffect(() => {
        if (!isInitialRender.current) {
            intervalRef.current = setInterval(nextImage, 5000);
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [nextImage]);

    const handleClick = (event) => {
        if (ignoreNextClick.current) {
            ignoreNextClick.current = false; // Disable the flag after the first click
            return; // Ignore this click
        }

        if (isTouchEvent.current) {
            isTouchEvent.current = false;
            // console.log('Click triggered by touch event');
            return;
        }

        event.stopPropagation();
        event.preventDefault();
        const { clientX } = event;
        const screenWidth = window.innerWidth;
        if (clientX < screenWidth / 2) {
            prevImage();
        } else {
            nextImage();
        }
    };

    const handleTouchStart = (event) => {
        if (event.type === 'touchstart') {
            // console.log('Touch start');
            isTouchEvent.current = true;
            touchStartX.current = event.touches[0].clientX;
        }
    };

    const handleTouchEnd = (event) => {
        if (!isTouchEvent.current || touchStartX.current === null) return;
        // console.log('Touch end');
        const touchEndX = event.changedTouches[0].clientX;
        const touchDifference = touchStartX.current - touchEndX;

        if (Math.abs(touchDifference) > 50) {
            if (touchDifference > 0) {
                nextImage();
            } else {
                prevImage();
            }
        }

        touchStartX.current = null;
        isTouchEvent.current = false;
    };

    return (
        <div
            style={styles.overlay}
            onClick={handleClick}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            <div style={styles.imageContainer}>
                <img src={images[currentIndex]} alt="Centered" style={styles.image} />
                <div style={styles.progressBarContainer}>
                    {images.map((_, index) => (
                        <div
                            key={index}
                            style={{
                                ...styles.progressBar,
                                width: `${100 / images.length}%`,
                                opacity: index === currentIndex ? 1 : 0.5,
                                animation: index === currentIndex ? 'progress 5s linear infinite' : 'none',
                                WebkitAnimation: index === currentIndex ? 'progress 5s linear infinite' : 'none',
                            }}
                        ></div>
                    ))}
                </div>
            </div>
            <div style={styles.closeButton} onClick={(e) => { e.stopPropagation(); onClose(); }}>✕</div>
        </div>
    );
};


const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        WebkitOverflowScrolling: 'touch',
    },
    progressBarContainer: {
        position: 'absolute',
        top: '10px',
        left: '10px',
        right: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '5px',
    },
    progressBar: {
        height: '6px',
        backgroundColor: 'white',
        margin: '0 5px',
    },
    imageContainer: {
        position: 'relative',
        width: 'fit-content',
        height: '100%',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        width: 'fit-content',
        height: '100%',
        objectFit: 'cover',
    },
    closeButton: {
        position: 'absolute',
        top: '50px',
        right: '50px',
        fontSize: '24px',
        color: 'white',
        cursor: 'pointer',
    },
};

FullScreenOverlay.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClose: PropTypes.func.isRequired,
};

export default FullScreenOverlay;
