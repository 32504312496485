import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: t('When?'),
      answer: t('You know when.')
    },
    ,
    {
      question: t('Are there any parking spaces?'),
      answer: t('Yes')
    },
    {
      question: t('Can I dance?'),
      answer: t('You must dance. Bring your best moves.')
    },
    {
      question: t('How is the venue?'),
      answer: t('We like it.')
    }
  ];

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id='faq' className='section'>
      <h2>{t("Some Questions You Might Have")}</h2>
      <p className='desc'>{t("If you still have questions, feel welcome to reach out.")}</p>
      {/* <div className='faq-list'>
        {faqs.map((faq, index) => (
          <div key={index} className='faq-item'>
            <div className='faq-question' onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default FAQ;
